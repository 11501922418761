.loading__container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding-top: 300px;
  height: 100vh;
}

.loading {
  width: 60px;
  height: 60px;
  border: 5px solid #ccc;
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
