.cart__container {
    background-color: #E3E6E6;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.cart__content {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    padding: 40px 20px;

}

.cart__left, .cart__right {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-radius: 8px;
}

.cart__left > span {
    font-size: 28px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    width: 100%;
    padding-bottom: 10px;
}

.cart__item {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 0.5rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    padding: 20px 0;
    max-height: 220px;
}

.item__left {
    width: 170px;
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3e6e634;

}

.item__left > img {
    max-width: 100%;
    max-height: 100%;
    padding: 4px;
    cursor: pointer;
}

.item__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 40px;
}

.item__details {
    display: flex;
    flex-direction: column;
    gap: 4px;

}

.item__name {
    font-size: 18px;
    cursor: pointer;
}

.item__name:hover {
    text-decoration: underline;
    color: #f5ab4a;
}

.item__price {
    font-size: 18px;
    font-weight: 700;
}

.item__stock {
    font-size: 14px;
    padding: 0;
}

.item__prime {
    padding: 0;
}

.item__edit-delete {
    display: flex;
    align-items: center;
    gap: 10px;
}

.item__edit-delete > span {
    border-left: 1px solid rgba(128, 128, 128, 0.295);
    padding-left: 8px;
    font-size: 14px;
    color: #007185;
    cursor: pointer;
}

.item__edit-delete > span:hover {
    text-decoration: underline;
}

.cart__total {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
}

.total {
    font-size: 18px;
}

.total > span {
    font-weight: 700;
}

.cart__right {
    width: fit-content;
}

.cart__checkout {
    border-radius: 6px;
    border: none;
    height: 30px;
    background-color: #FFD814;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.cart__checkout:hover {
    background-color: #dbba16;
}

.cart__no-items {
    width: 1000px;
    background: white;
    height: fit-content;
    background-color: white;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.cart__no-items > span {
    font-size: 24px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    width: 100%;
    padding-bottom: 10px;
}

.cart__no-items > p {
    font-size: 16px;
    margin-top: 12px;
}

.cart__no-items > p > span {
    color: #007185;
    cursor: pointer;
}


/* CHECKOUT */
.checkout__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.checkout__header {
    border: 1px solid #ddd;
    background-color: #f0f0f0ad;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.checkout__header > img:first-child {
    margin-top: 10px;
}

.checkout__header > img {
    max-width: 170px;
    max-height: 45px;
}

.checkout__header > p {
    font-size: 28px;
}

.checkout__header > p > span {
    color: #007185;
}

.checkout__content-container {
    display: flex;
    justify-content: center;
    padding: 12px;

}

.checkout__content {
    max-width: 1100px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
}

.checkout__left {
    display: flex;
    flex-direction: column;
}

.checkout__address {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.5rem;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    margin-bottom: 10px;
}

.checkout__address-content {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.checkout__address-content > p:first-child {
    color: #007185;
}

.checkout__address-content > span {
    font-weight: 600;
}

.checkout__items > h3 {
    margin-bottom: 5px;
}

.checkout__items-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 10px;
}

.checkout__item {
    border: 1px solid rgba(128, 128, 128, 0.295);
    padding: 8px;
    border-radius: 8px;
}

.checkout__item-content {
    display: grid;
    grid-template-columns: 0.7fr 3fr 0.3fr;
    gap: 1rem;
    padding: 4px;
}

.checkout__item-left {
    width: 120px;
    height: 120px;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout__item-left > img {
    max-width: 100%;
    max-height: 100%;
    padding: 8px;
}

.checkout__item-right {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.checkout__item-title {
    font-weight: 600;
}

.checkout__item-right > p {
    font-size: 14px;
}

.checkout__item-price > span {
    color: #b12704;
    font-weight: 600;
}

.checkout__item-quantity > span {
    color: #007185;
}

.checkout__item-sold {
    font-size: 12px;
    color: #565656;
}

.checkout__item-header > h4 {
    font-size: 18px;
    color: #007600;
}

.checkout__item-header > p {
    font-size: 14px;
    color: #565656;
}

.checkout__right {
    border: 1px solid rgba(128, 128, 128, 0.295);
    padding: 8px;
    border-radius: 8px;
    height: fit-content;
}

.checkout__right-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkout__right-header > button {
    width: 100%;
}

.checkout__right-header > p {
    font-size: 12px;
    color: #565656;
    text-align: center;
    padding: 5px 0;
}

.checkout__right-summary {
    border-top: 1px solid rgba(128, 128, 128, 0.295);
    padding-top: 5px;
}

.checkout__right-summary > h3 {
    padding: 5px 0;
    font-size: 18px;
}

.checkout__right-summary-content {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.checkout__right-summary-left > p, .checkout__right-summary-right > p {
    font-size: 12px;
    color: #565656;
    line-height: 20px;
}

.space {
    padding-bottom: 5px;
}

.checkout__right-summary-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.checkout__right-summary-left {
    display: flex;
    flex-direction: column;
}

.checkout__right-summary-right-border {
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);

}

.checkout__right-summary-left > span, .checkout__right-summary-right > span {
    font-size: 18px;
    color: #b12704;
    padding: 5px 0;
    font-weight: 600;
}

.checkout__right-header > button {
    border-radius: 6px;
    border: none;
    height: 30px;
    background-color: #FFD814;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.checkout__right-header > button:hover {
    background-color: #dbba16;
}


/* CONFIRMATION */
.confirmation__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px;
    width: 420px;
    gap: 0.5rem;
}

.confirmation__container > h3 {
    color: #007600;
}

.confirmation__container > h3 > i {
    padding-right: 5px;
}

.confirmation-order {
    font-size: 14px;
}

.confirmation-order > span {
    color: #007185;
    cursor: pointer;
}

.confirmation-deliver {
    font-size: 14px;
}

.confirmation-deliver > span {
    font-weight: 600;
}

.confirmation__container > div {
    font-size: 14px;
}

.confirmation__container > div > span {
    font-weight: 600;
}
