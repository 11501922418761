/* TODO Add site wide styles */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Amazon Ember", Arial, sans-serif;
}

textarea {
    resize: vertical;
    max-width: 100%;
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: 400;
    src: url('https://d1irxr40exwge2.cloudfront.net/AmazonEmber_Rg.ttf');
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: 500;
    src: url('https://d1irxr40exwge2.cloudfront.net/Amazon-Ember-Medium.ttf');
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: 700;
    src: url('https://d1irxr40exwge2.cloudfront.net/AmazonEmber_Bd.ttf');
}
