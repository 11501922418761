.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.nav__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #131A22;
  color: white;
  height: 65px;
  gap: 2.5rem;
  padding: 0 20px;
}

.nav__logo {
  color: white;
  text-decoration: none;
  width: 190px;
  margin-top: 12px;
}

.nav__logo > img {
  width: 100%;
}

.nav__selling > p {
  color: #CCCCCC;
  font-size: 12px;
}

.nav__box > p {
  font-size: 12px;
  cursor: pointer;
}

.nav__selling > span, .nav__box > span {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.nav__search-bar {
  display: flex;
  align-items: center;
  height: 45px;
  flex: 1;
}

.nav__search-bar > div {
  font-size: 12px;
  background-color: #F3F3F3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: black;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.nav__search-bar > input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 8px;
  font-size: 14px;
}

.nav__search-bar > i {
  background-color: #FEBD69;
  padding: 8px 12px;
  color: black;
  height: 100%;
  display: flex;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.nav__lang {
  width: 30px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  height: 14px;
}

.nav__lang > img {
  width: 70%;
  height: 100%;
  object-fit: cover;
}

.nav__lang > span {
  font-size: 14px;
  font-weight: 600;
}

.nav__box > span > i {
  color: gray;
  padding-left: 3px;
  font-size: 12px;
}

.account__sec {
  position: relative;
}

.account__sec > p, .account__sec > span {
  cursor: pointer;
}


.account__container {
  position: fixed;
  border: 1px solid gray;
  color: black;
  background-color: white;
  display: flex;
  right: 175px;
  border-radius: 3px;
  gap: 0.7rem;
  /* width: 200px; */
  justify-content: center;
  padding: 12px 16px;
  top: 58px;
  z-index: 999;
}

.account__login-container {
  position: fixed;
  border: 1px solid rgba(128, 128, 128, 0.411);
  color: black;
  background-color: white;
  display: flex;
  flex-direction: column;
  right: 165px;
  border-radius: 3px;
  width: 200px;
  justify-content: center;
  padding: 12px 16px;
  top: 55px;
  z-index: 999;
}

.account__login-container > button {
  border: none;
  background-color: #FFD814;
  padding: 6px 40px;
  border-radius: 5px;
  cursor: pointer;
}

.account__login-container > p {
  padding-top: 8px;
  font-size: 13px;
}

.account__login-container > p > span {
  color: #007185;
  cursor: pointer;
}

.account__login-container > p > span:hover {
  text-decoration: underline;
}

.account__info-right {
  display: flex;
  flex-direction: column;
}

.account__info > h4 {
  padding: 8px 0;
}

.account__right {
  border-left: 1px solid rgba(128, 128, 128, 0.39);
  padding-left: 12px;
}

.account__info > span, .account__info-right > span, .account__info-right > div {
  font-size: 14px;
  color: #444;
  line-height: 24px;
}

.account__info > span:hover, .account__info-right > span:hover {
  text-decoration: underline;
  color: #f5ab4a;
  cursor: pointer;
}

.account__info > p {
  font-size: 12px;
  padding-left: 4px;
  color: #444;
  line-height: 16px;
}

.category__container {
  background-color: #232F3E;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  font-size: 14px;
  padding: 10px 0;
}

.category__container > span {
  cursor: pointer;
}

.nav__cart {
  cursor: pointer;
}

.nav__cart > p {
  color: #f08804;
  font-size: 18px;
}

.nav__cart > img {
  max-height: 30px;
  max-width: 30px;
  display:inline-block;
  transform:translatex(-12px) translatey(-8px);
}
