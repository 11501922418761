.review-form__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.review-form__content {
    max-width: 800px;
    padding: 35px 15px;
}

.review-form__content > form {
    display: flex;
    flex-direction: column;
}

.review-form__header {
    border-bottom: 1px solid rgba(128, 128, 128, 0.400);
}

.review-form__header > h1 {
    font-size: 24px;
}

.review-form__header-info {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    padding: 20px 10px;
}

.review-form__header-info > img {
    max-height: 150px;
    max-width: 150px;
}

.review-form__header-info > span {
    display: flex;
    align-items: center;
}

.review-form__input {
    padding: 14px 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.400);
    display: flex;
    flex-direction: column;
}

.review-form__input > label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

.review-form__input > input {
    height: 31px;
    padding: 3px 7px;
    font-size: 14px;
    border-radius: 6px;
    margin: 5px 0;
    outline: none;
    border: 1px solid #888c8c;
}

.review-form__input > input:focus, .review-form__input > textarea:focus {
    background-color: #F7FEFF;
    border: 1px solid #e77600;
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
}

.review-form__input > span {
    font-size: 14px;
    color: #565659;
}

.review-form__input > textarea {
    padding: 3px 7px;
    font-size: 14px;
    border-radius: 6px;
    margin: 5px 0;
    outline: none;
    border: 1px solid #888c8c;
    height: 130px;
}

.review__submit {
    display: flex;
    justify-content: flex-end;
    padding: 14px 0;
}

.review__submit > button {
    border: none;
    background-color: #FFD814;
    padding: 8px 14px;
    border-radius: 10px;
    cursor: pointer;
}

.review__submit > button:hover {
    background-color: #dbba16;
}

.review-errors {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;

}

.review-errors > span {
    font-size: 16px;
    font-style: italic;
    padding: 0 2px;
}

.form__file-input {
    display: none;
}

.form__file-upload {
    margin-top: 10px;
    width: 90px;
    height: 90px;
    border: 2px dashed #aab7b8;
    font-size: 24px;
    color: #aab7b8;
    cursor: pointer;
    background: #aab7b821;
    border-radius: 8px;
}

.form__file-pic {
    margin-top: 10px;
    width: 90px;
    height: 90px;
    border-radius: 8px;
    border: none;

}

.form__file-pic > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;

}

.review__star {
    font-size: 24px;
    cursor: pointer;
}
