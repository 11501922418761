.landing__container {
    background-color: #E3E6E6;
    /* height: 130vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing__banner {
    max-width: 1250px;
    /* max-height: 1000px; */
    position: relative;
}

.landing__banner > img {
    height: 100%;
    width: 100%;
    padding: 3px 5px;
}

.landing__categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    padding: 0 20px;
    position: absolute;
    bottom: -200px;
}

.landing__category {
    max-width: 350px;
    background-color: white;

    padding: 15px;
}

.landing__category > img {
    width: 100%;
    height: 280px;
}

.landing__category > h3 {
    padding-bottom: 8px;
}

.landing__category > span {
    color: #0066c0;
    font-size: 13px;
    cursor: pointer;
}

.landing__category > span:hover {
    text-decoration: underline;
    color: #f5ab4a;
}

.landing__trending-container {
    /* margin-top: 250px;
    max-width: 1210px;
    width: 100%;
    background-color: white;
    padding: 0 20px; */

    margin-bottom: 50px;
}

.first {
    margin-top: 250px;

}

.landing__trending {
    padding: 8px 0;
    margin-bottom: 45px;
    /* background-color: white; */
    max-width: 1210px;
    width: 100%;
    background-color: white;
    padding: 0 20px;
}

.landing__trending-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
    padding-top: 10px;
}

.landing__trending-header > span {
    font-size: 13px;
    color: #0066c0;
    padding-left: 10px;
    cursor: pointer;
}

.landing__trending-header > span:hover {
    text-decoration: underline;
    color: #f5ab4a;
}

.landing__trending-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-width: 1250px;
    gap: 1rem;
    width: 100%;
    cursor: pointer;
}

.landing__trending-product {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid rgba(194, 194, 194, 0.158);
    box-shadow: 2px 2px 2px rgba(212, 212, 212, 0.199);
    margin: 8px 0;
}

.landing-product__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    width: 100%;
}

.landing-product__image > img {
    max-height: 170px;
    max-width: 170px;
    padding: 4px;
}

.landing__trending-product > span {
    font-size: 13px;
    padding: 18px 4px 10px 7px;
    justify-content: flex-end;
    align-items: baseline;
}

.landing__trending > h3 {
    font-size: 22px;
    padding: 8px 0;
}

.landing__trending-product > span:hover {
    text-decoration: underline;
    color: #f5ab4a;
}
