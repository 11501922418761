.reviews__container {
    border-top: 1px solid rgba(128, 128, 128, 0.295);
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2.5rem;
    max-width: 1500px;
    padding: 40px 40px;
    overflow: hidden;
    width: 100%;
}

.reviews__left {
    display: flex;
    flex-direction: column;
}

.reviews__left > h3 {
    font-size: 21px;
    padding-bottom: 4px;
}

.reviews__rating {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 18px;
}

.reviews__left > p {
    font-size: 14px;
    color: #565659;
    padding: 6px 0;

}

.reviews__rating-percent {
    display: flex;
    flex-direction: column;
    padding: 8px 0 16px 12px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
}

.reviews__rating-percent > span {
    font-size: 14px;
    color: #007185;
    padding: 2px 0;
}

.reviews__create-review {
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    display: flex;
    flex-direction: column;
    padding: 40px 0;
}

.reviews__create-review > h4 {
    font-size: 18px;
}

.reviews__create-review > span {
    font-size: 14px;
    padding: 8px 0;
}

.reviews__create-review > button {
    font-size: 13px;
    padding: 4px;
    background: none;
    border: 1px solid rgba(128, 128, 128, 0.356);
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.24);
    cursor: pointer;
    height: 30px;
    border-radius: 8px;
}

.reviews__create-review > button:hover {
    background-color: rgba(228, 228, 228, 0.322);
}


.reviews__right {
    display: flex;
    flex-direction: column;
    max-width: 750px;
    gap: 1.5rem;
}

.reviews__right > h4 {
    font-size: 18px;
}

.review__header {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding-bottom: 4px;
}

.review__header > img {
    height: 30px;
}

.review__header > span {
    font-size: 14px;
}

.review__subheader {
    display: flex;
    font-size: 14px;
    gap: 0.7rem;
}

.review__subheader > span {
    font-weight: 700;
}

.review__container > span {
    font-size: 14px;
    color: #565659;
}

.review__verified {
    color: #c45500;
    font-size: 12px;
    padding: 6px 0;
}

.review__container > p {
    font-size: 14px;
    line-height: 20px;
}

.review__container > img {
    margin: 8px 0;
    max-height: 150px;
    max-width: 150px;
}

.review__user-btns {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 8px;
}

.review__user-edit {
    font-size: 13px;
    background: none;
    border: 1px solid rgba(128, 128, 128, 0.356);
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.24);
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 8px;

}

.review__user-delete > button{
    color: red;
    border: none;
    background: none;
    border-left: 1px solid rgba(128, 128, 128, 0.295);
    padding-left: 8px;
    cursor: pointer;
}
