.delete__container {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-radius: 8px;
}

.delete__header {
    background-color: #F0F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.delete__header > i {
    font-size: 14px;
    cursor: pointer;
}

.delete__container > span {
    padding: 20px 20px;
    font-size: 14px;
}

.delete__btns {
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.3rem;
    font-size: 14px;
}

.delete__close {
    font-size: 13px;
    background: none;
    border: 1px solid rgba(128, 128, 128, 0.295);
    padding: 2px 10px;
    border-radius: 6px;
    cursor: pointer;
}

.delete__close:hover {
    background-color: rgba(228, 228, 228, 0.322);
}

.delete__delete {
    font-size: 13px;
    background-color: #FFD814;
    padding: 2px 10px;
    border: 1px solid rgba(128, 128, 128, 0.295);
    border-radius: 6px;
    cursor: pointer;
}

.delete__delete:hover {
    background-color: #dbba16;
}
