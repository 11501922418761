.login__container {
    /* width: 50vw; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.login__container > img {
    width: 250px;
    cursor: pointer;
    margin-bottom: 10px;
}

.login__form {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.356);
    border-radius: 5px;
    padding: 16px;
    width: 400px;
}

.login__form-title {
    font-size: 28px;
}

.login__form > form {
    display: flex;
    flex-direction: column;
}

.login__form > form > label {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
}

.login__form > form > label > input {
    margin-top: 4px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.568);
    outline: none;
    padding-left: 5px;
}

.login__form > form > label > input:focus {
    outline: 2px solid rgba(87, 196, 196, 0.514);
}

.login__form > form > button {
    margin: 8px 0;
    height: 30px;
    background-color: #FFD814;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.login__form > form > button:hover {
    background-color: #dbba16;
}

.login__form > p {
    font-size: 11px;
}

.login__form > p > span {
    color: #0066c0;
    cursor: pointer;
}

.login__demo {
    margin-top: 8px;
    color: #0066c0;
    cursor: pointer;
    font-size: 15px;
}

.login__demo:hover {
    text-decoration: underline;
}

.login__demo > i {
    font-size: 12px;
    color: gray;
}


.login__signup-btn {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.login__separator {
    margin: 20px 0 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__sep-border {
    border: 1px solid lightgray;
    width: 100%;
}

.login__sep-text {
    align-self: center;
    padding: 0 0 0 8px;
    font-size: 12px;
    width: 100%;
    color: rgb(97, 97, 97);
}

.login__signup-btn > button {
    margin: 8px 0;
    border: 1px solid rgba(128, 128, 128, 0.356);
    background: none;
    border-radius: 6px;
    height: 35px;
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.24);
    cursor: pointer;
}

.login__signup-btn > button:hover {
    background-color: rgba(228, 228, 228, 0.322);
}

.login__footer {
    margin-top: 35px;
    border-top: 2px solid rgba(128, 128, 128, 0.158);
    width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__footer-links {
    margin: 18px 0;
    display: flex;
    align-items: center;
    gap: 5rem;

}

.login__footer-links > a {
    text-decoration: none;
    color: #0066c0;
    font-size: 14px;
}

.login__footer-links > a:hover {
    text-decoration: underline;
}

.login__copyright {
    font-size: 12px;
}

.signup__login {
    font-size: 14px;
    margin-top: 18px;
}

.signup__login > span, .signup__login > i {
    color: #0066c0;
}

.signup__login > span:hover {
    cursor: pointer;
    text-decoration: underline;
}


.errors {
    color: red;
    font-size: 12px;
}

.errors > span {
    font-size: 16px;
    font-style: italic;
    padding: 0 2px;
}
