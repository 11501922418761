.product__display-img {
    display: flex;
    align-items: center;
    justify-content: center;

}

.product__display-img > img {
    padding: 12px;
    min-width: 300px;
    max-width: 440px;
}

.prime-logo {
    width: 53px;
    padding: 5px 0;
}

.product__container {
    /* height: 200vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.product__display-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.70fr;
    gap: 1.2rem;
    max-width: 1500px;
    padding: 40px 10px;
    overflow: hidden;
    width: 100%;
    height: 70vh;
}

.product__display-info {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.product__name {
    font-size: 24px;
}

.product__category {
    font-size: 14px;
    color: #007185;
    cursor: pointer;
}

.product__display-reviews {
   font-size: 14px;
   display: flex;
   gap: 0.3rem;
   border-bottom: 1px solid rgba(128, 128, 128, 0.295);
   padding-bottom: 10px;
}

.product__stars {
    color: #FF9900;
}

.product__display-reviews > p {
    padding-left: 10px;
    color: #007185;
}

.product__price {
    font-size: 13px;
    display: flex;
    padding-top: 5px;
}

.product__price > p {
    padding-top: 5px;
}

.product__price > span {
    font-size: 28px;
}

.product__returns {
    font-size: 14px;
    color: #007185;
}

.product__prime-visa {
    font-size: 14px;
    color: #007185;
    font-weight: 700;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    padding-bottom: 10px;
}

.product__prime-visa > span {
    color: #b12704;
}

.product__description > h4 {
    font-size: 17px;
    padding-bottom: 4px;
}

.product__description-list {
    padding-left: 18px;
}

.product__description-list > li {
    padding: 3px 0;
    font-size: 15px;
}

.product__display-buy {
    border: 1px solid rgba(128, 128, 128, 0.295);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.product__buy-title {
    font-weight: 700;
    font-size: 14px;
}

.product__buy-delivery {
    padding-top: 10px;
    font-size: 14px;
}

.product__buy-delivery > span {
    font-weight: 700;
}

.product__buy-delivery-order {
    font-size: 14px;
}

.product__buy-delivery-order > span {
    color: #007600;
}

.product__buy-address {
    font-size: 14px;
    padding-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.product__buy-address > span {
    color: #007185;
}

.product__buy-in-stock {
    padding-top: 8px;
    font-size: 18px;
    color: #007600;
    display: flex;
    flex-direction: column;
}

.product__buy-in-stock > span {
    font-size: 14px;
    color: #b12704;
}

.product__buy-no-stock {
    color: #b12704;
    padding-top: 8px;
}

.product__buy-quantity {
    width: fit-content;
    border-radius: 6px;
    margin: 10px 0;
    border-color: #D5D9D9;
    background-color: rgba(173, 173, 173, 0.226);
    font-size: 13px;
    outline: none;
}

.product__buy-button {
    width: 95%;
    align-self: center;
    border-radius: 16px;
    border: none;
    height: 30px;
    background-color: #FFD814;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
}

.product__buy-button:hover {
    background-color: #dbba16;
}

.product__buy-extra {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    gap: 10px;
}

.product__buy-extra > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
}

.product__buy-extra-left {
    color: #565959;
}

.product__buy-extra-secure {
    color: #007185;
}

.disabled {
    cursor: not-allowed;
}
